<template>
<v-dialog
      v-model="alwaysOpen"
      :fullscreen="isMobile"
      width="80%"
      max-width="512px"
      @click:outside="close"
>
<v-card class="form-dialog">
<h1 class="form-dialog__title">
{{ $t('delete_many_users_form_title') }}
</h1>
  <p class="form-dialog__subtitle">
{{ $t('delete_many_users_form_subttitle') }}
</p>
<v-table class="table">
<tr @click="deleteReason(0)" class="blackTr bottom-border">
      <td class="bottom-border">{{ $t('delete_many_users_form_option_1') }}</td>
      <td class="arrow--has-bottom-border">
      <img src="@/assets/arrows.svg" />
      </td>
</tr>
<tr @click="deleteReason(1)" class="blackTr bottom-border">
      <td class="bottom-border">{{ $t('delete_many_users_form_option_2') }}</td>
      <td class="arrow--has-bottom-border">
      <img src="@/assets/arrows.svg" />
      </td>
</tr>
<tr @click="deleteReason(2)" class="blackTr bottom-border">
      <td class="bottom-border">{{ $t('delete_many_users_form_option_3') }}</td>
      <td class="arrow--has-bottom-border">
      <img src="@/assets/arrows.svg" />
      </td>
</tr>
<tr @click="deleteReason(3)" class="blackTr bottom-border">
      <td class="bottom-border">{{ $t('delete_many_users_form_option_4') }}</td>
      <td class="arrow--has-bottom-border">
      <img src="@/assets/arrows.svg" />
      </td>
</tr>
<tr @click="deleteReason(3)" class="blackTr bottom-border">
      <td>{{ $t('delete_many_users_form_option_5') }}</td>
      <td class="arrow"><img src="@/assets/arrows.svg" /></td>
</tr>
<tr class="backTr">
      <td colspan="2">
      <v-row class="form-dialog__button-wrap">
      <v-col cols="12">
            <v-btn
            class="pkmn-button"
            outlined
            color="primary"
            @click="close"
            >
            {{ $t('form_cancel')}}
            </v-btn>
      </v-col>
      </v-row>
      </td>
</tr>
</v-table>
</v-card>
</v-dialog>
</template>

<script>
// import api from '@/helpers/api'
import axios from 'axios'
export default {
  name: 'FormDeleteAccountReason',
  props: {
    data: Object
  },
  data() {
    return {
      alwaysOpen: true,
      errorMessage: null,
      mobileBreakpoint: 767
    }
  },
  computed: {
    locationName() {
      return this.$store.getters.currentLocation.name
    },
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async deleteReason(index) {
      this.$emit('delete', index)

      const reasons = ['Service coverage is poor', 
      'App is buggy / hard to use',
      'Price is too high',
      'We no longer need paid parking',
      'Other']
      const email = this.$store.state.userData.email
      const data = {
        fields: [
          {
            name: 'email',
            value: email
          },
          {
            name: 'reason_for_user_deletion_40_',
            value: reasons[index]
          }
        ]
      }

      try {
        await axios.post('https://api.hsforms.com/submissions/v3/integration/submit/2127423/439092f7-dc68-4764-9068-91f24ad9fa73', data)
      } catch (error) {

      }

    }
  },
  mounted() {
    // if user press Escape, close the dialog and clear all data
    // ONLY works if a component is NOT attacted to the view as a default (use v-if to decide whether a component is attacted or not)
    // Otherwise, all 'close' listener of components existing in a view will be notified
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.title-size {
  width: 302px !important;
}
.blackTr {
  color: var(--web-text-main, #333);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  height: 56px;
  vertical-align: middle;
  cursor: pointer;
}
.bottom-border {
  width: 426px;
  border-bottom: 1px solid #e5e5e5;
}

.arrow {
  width: 24px;
  align-content: right;
  text-align: right;

  &--has-bottom-border {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
