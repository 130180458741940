<template>
  <div class="page page--employees" :key="$store.state.updateTimestamp">
    <v-row>
      <v-col cols="12" sm="9" md="5">
        <h1 class="page__title">
          {{ $t('settings_team_title') }} ({{
            this.employees ? this.employees.length : 0
          }})
        </h1>
        <p class="page__subtitle">
          {{ $t('settings_team_text') }}
        </p>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        md="5"
        offset-md="2"
        align="right"
        align-self="end"
      >
        <v-btn
          class="employees__button employees__button--add pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          large
          @click="openAddForm"
        >
          {{ $t('home_add_employee') }}
        </v-btn>
        <group-selector
          class="employees__button employees__button--group-filter"
          @input="selectGroup"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <employees-data-table
          :data="employees"
          @edit="openEditForm"
          @delete="openDeleteReasonForm"
          @resendInvitation="resendInvitation"
        />
      </v-col>
    </v-row>

    <employee-form
      v-if="isAddEditFormOpening"
      ref="form"
      :data="selectedRecord"
      @submitAdd="addEmployee"
      @submitEdit="editEmployee"
      @close="isAddEditFormOpening = false"
    />

    <confirm-dialog
      ref="confirmDeleteDialog"
      warning
      :title="confirmDeleteTitle"
      :text="confirmDeleteText"
      @confirm="deleteEmployee"
      @close="selectedRecord = null"
    />

    <delete-user-reason-form
      v-if="isDeleteUserReasonFormOpening"
      @delete="confirmedDeleteUser"
      @close="closeDeleteUserForm"
    />
    <delete-account-reason-form
      v-if="isDeleteAccountReasonFormOpening"
      @delete="confirmedDeleteUser"
      @close="closeDeleteUserForm"
    />

    <form-add-user-down
      v-if="isShowingAddEmployeeDownPopUp"
      @close="isShowingAddEmployeeDownPopUp = false"
    />
  </div>

  
</template>

<script>
import auth from '@/auth'
import api from '@/helpers/api'
import helpers from '@/helpers'
import userHelpers from '@/helpers/user'
import paymentHelpers from '@/helpers/payment'

import ConfirmDialog from '@/components/ConfirmDialog'
import EmployeesDataTable from '@/components/Employees/DataTable'
import EmployeeForm from '@/components/Employees/FormEmployee'
import GroupSelector from '@/components/Groups/GroupSelector'
import DeleteUserReasonForm from '@/components/Employees/FormDeleteUserReason'
import DeleteAccountReasonForm from '@/components/Employees/FormDeleteAccountReason'
import FormAddUserDown from '../components/Employees/FormAddUserDown.vue'
export default {
  name: 'EmployeesView',
  components: {
    ConfirmDialog,
    EmployeesDataTable,
    EmployeeForm,
    GroupSelector,
    DeleteUserReasonForm,
    DeleteAccountReasonForm,
    FormAddUserDown
  },
  data() {
    return {
      auth: auth,
      confirmDeleteTitle: '',
      confirmDeleteText: '',
      selectedRecord: null,
      data: [],
      isAddEditFormOpening: false,
      isDeleteUserReasonFormOpening: false,
      isDeleteAccountReasonFormOpening: false,
      showAddEmployeeDownPopUp: true,
      isShowingAddEmployeeDownPopUp: false
    }
  },
  computed: {
    employees() {
      return this.data || []
    },
    maxEmployeesAmount() {
      return this.$store.state.maxTotalEmployeesInThisSession

    }
  },
  methods: {
    selectGroup(group) {
      // @todo: track data
      this.loadData(true)
    },
    openAddForm() {
      // this.isShowingAddEmployeeDownPopUp = true
      this.selectedRecord = {}
      this.isAddEditFormOpening = true
    },
    openEditForm(record) {
      this.selectedRecord = Object.assign({}, record)
      this.isAddEditFormOpening = true

    },
    openDeleteReasonForm(record) {
      this.selectedRecord = Object.assign({}, record)
      const maxEmployeesAmount = this.$store.state.maxTotalEmployeesInThisSession
      if (this.data.length * 10 < maxEmployeesAmount * 6) {
        this.isDeleteAccountReasonFormOpening = true
      } else {
        this.isDeleteUserReasonFormOpening = true
      }
    },
    closeDeleteUserForm() {
      this.isDeleteAccountReasonFormOpening = false
      this.isDeleteUserReasonFormOpening = false
      this.selectedRecord = {}
    },
    confirmedDeleteUser() {
      this.isDeleteAccountReasonFormOpening = false
      this.isDeleteUserReasonFormOpening = false
      this.deleteEmployee()
    },
    async addEmployee(data) {
      this.isAddEditFormOpening = false
      await this.loadData(true)
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('added_confirm_message', {
          object: `${this.$t('g_employee')} "${data.name}"`
        })
      })
    },
    getDeleteDialogDescription(record) {
      let descriptionText = ''
      if (record.subscription !== undefined) {
        const packagePrices =
          record.subscription.account_package.package.prices[0]
        if (packagePrices.renewal_interval === 'first_day_of_the_month') {
          descriptionText =
            packagePrices.cost > 0
              ? this.$t('remove_employee_premium')
              : this.$t('remove_employee_flex')
        }
      }
      return descriptionText
    },
    async editEmployee(data) {
      this.isAddEditFormOpening = false
      await this.loadData(true)

      // invitation stores name in "name" field, while employee stores name in first_name & last_name field
      const employeeName = data.name || `${data.first_name} ${data.last_name}`
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('updated_confirm_message', {
          object: `${this.$t('g_employee')} "${employeeName}"`
        })
      })
    },
    async deleteEmployee() {
      
      if (this.selectedRecord.user_account_id === this.$store.state.userData.account_id) { return }
      let name

      let url = ''
      // build data based on employee or invitation
      if (this.selectedRecord.user_account_id) {
        // employee case
        name = `${this.selectedRecord.first_name || ''} ${this.selectedRecord.last_name || ''}`
        url = `v1/groups/${this.selectedRecord.group_id}/users/${this.selectedRecord.user_id}`
      } else {
        // invitation case
        name = this.selectedRecord.name
        url = `v1/b2b/groups/${this.selectedRecord.group_id}/invitation/${this.selectedRecord.id}`
      }

      // @todo: handle deleting invitation later
      const apiSettings = {
        method: 'delete',
        service: 'users',
        url: url
      }

      try {
        // call backend to remove employee
        await api.promise(apiSettings)
        // reload data
        await this.loadData(true)
        // display success message
        this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('removed_confirm_message', {
            object: `${this.$t('g_employee')} "${name}"`
          })
        })
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },
    async resendInvitation(invitation) {
      const apiSettings = {
        method: 'post',
        service: 'users',
        url: `v1/b2b/groups/${this.$store.state.selectedGroup}/invitation/${invitation.id}/resend`,
        data: {
          inviter_profile_id: invitation.inviter_profile_id,
          language_code: this.$i18n.locale
        }
      }

      try {
        await api.promise(apiSettings)
        this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('invitation_sent', { recipient: invitation.name })
        })
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },
    async loadData(isForceUpdate) {
      // fetch company employee list by group
      let employees = this.$store.state.employeeData
      if (employees && !isForceUpdate) {
        this.data = employees
        return
      }

      let company = this.$store.state.companyData
      let selectedGroup = this.$store.state.selectedGroup
      if (!company || !selectedGroup) {
        await helpers.fetchDashboardData()
        company = this.$store.state.companyData
        selectedGroup = this.$store.state.selectedGroup
      }

      // fetch role options for form dropdown input
      if (!this.$store.state.roleOptions) {
        await userHelpers.fetchRoleOptions()
      }

      // fetch profile id of the logged-in user, to use as inviter profile id
      const userProfileInCompany = await userHelpers.fetchUserProfileInCompany(
        helpers.getTokenInfo().uid,
        company.id
      )
      this.$store.state.userData.profile_id_in_company = userProfileInCompany.id

      // fetch company available pricing packages
      await paymentHelpers.fetchCompanyPricingPackages(company.id)

      // fetch selected group's employee
      employees = await userHelpers.fetchGroupEmployees(selectedGroup)
      this.data = employees
    }
  },
  async mounted() {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.employees__button {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: block;
    width: 100%;
  }
}

.employees__button--group-filter {
  margin-left: 16px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: 0;
    margin-top: 8px;
  }
}
</style>
