<template>
      <v-dialog v-model="alwaysOpen" :fullscreen="isMobile" width="80%" max-width="512px" @click:outside="close">
            <v-card class="form-dialog">
                  <v-icon class="form-dialog__button form-dialog__button--close" @click="close">
                        mdi-close
                  </v-icon>

                  <h1 class="form-dialog__title">
                        {{ $t('add_user_down_title') }}
                  </h1>
                  <p class="form-dialog__subtitle">
                        {{ $t('add_user_down_subtitle') }}
                  </p>
                  <v-row style="height: 80px;">

                  </v-row>
                  <v-row dense class="form-dialog__button-wrap">
                        <v-col cols="12">
                              <v-btn class="pkmn-button" outlined color="primary" @click="close">
                                    {{ $t('button_back') }}
                              </v-btn>
                        </v-col>
                  </v-row>
            </v-card>
      </v-dialog>
</template>

<script>
export default {
  name: 'FormAddUserDown',
  props: {
    data: Object
  },
  data() {
    return {
      alwaysOpen: true,
      errorMessage: null,
      mobileBreakpoint: 767
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.title-size {
  width: 302px !important;
}
.blackTr {
  color: var(--web-text-main, #333);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  height: 56px;
  vertical-align: middle;
  cursor: pointer;
}
.bottom-border {
  width: 426px;
  border-bottom: 1px solid #e5e5e5;
}

.arrow {
  width: 24px;
  align-content: right;
  text-align: right;

  &--has-bottom-border {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
